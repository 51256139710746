<template>
	<div class="relation">
		<img src="../../../assets/imges/index/5.png" class="headerimg" />
		<div class="relationinfo">
			<div class="relationheader">
				<span class="title1">关于我们</span><span class="arrows">></span><span class="title2">联系我们</span>
			</div>
			<div class="service">
				<div class="serviceinfo">
					<div class="servicecard">
						<div class="servicetitle">
							<img src="../../../assets/index/4.png" />
							<span>售前咨询</span>
						</div>
						<div class="servicetext">
							立即致电销售人员进行购买咨询，或预约技术获取全面的技术解决方案
						</div>
						<div class="servicephone">
							<img src="../../../assets/index/phone.png" style="vertical-align: middle;"/>
							<span style="vertical-align: middle;">400-0365558</span>
						</div>
						<div class="servicebutton">
							<el-button type="primary" >联系我们</el-button>
						</div>
					</div>
					<div class="onlinecard">
						<div class="onlinetitle">
							<img src="../../../assets/index/4.png" />
							<span>在线支持</span>
						</div>
						<div class="onlinetext">
							智能诊断，及时回复，为您提供快速便捷的服务体验，欢迎您咨询邦伲德！
						</div>
						<div class="onlinebutton">
							<el-button type="primary" >立即咨询</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
	  data () {
	    return {
	
	    }
	  }
	}
</script>

<style scoped lang="less">
	.relation{
		background-color: #F5F5F9;
		.relationinfo{
			width: 80%;
			margin: 0 auto;
			padding-top: 20px;
			.relationheader{
				padding: 59px 0;
				font-size: 28px;
				border-bottom: 1px solid #979797;
				.title1{
					font-family: SourceHanSansCN-Bold, SourceHanSansCN;
					font-weight: bold;
				}
				.arrows{
					color: #BEBEBE;
					padding: 0 25px;
				}
				.title2{
					font-weight: 500;
					color: #2F7FFC;
				}
			}
			.service{
				margin: 0 auto;
				margin-top: 88px;
				.serviceinfo{
					display: flex;
					 justify-content:center;
					margin-top: 92px;
					padding-bottom:94px ;
					.servicecard{
						background-image: url(../../../assets/index/service1.png);
						background-size: cover;
						width: 556px;
						height: 184px;
						border-radius: 10px;
						padding: 52px 60px;
						.servicetitle{
							font-size: 24px;
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
							font-weight: bold;
							span{
								margin-left: 7px;
							}
						}
						.servicetext{
							margin-top: 29px;
							margin-left: 10px;
							font-size: 16px;
							font-family: Medium;
							font-weight: 500;
							color: #777777;
							line-height: 28px;
							width: 376px;
						}
						
						.servicephone{
							font-size: 24px;
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
							font-weight: bold;
							margin-top: 26px;
							span{
								margin-left: 7px;
								font-size: 20px;
								font-family: Medium;
								font-weight: 500;
								color: #FFDA0A;
							}
						}
						
						.servicebutton{
							// position: absolute;
							text-align: right;
							margin-top: 42px;
							.el-button{
								width: 128px;
								height: 55px;
								background: linear-gradient(270deg, #0A89FF 0%, #4371FF 100%);
							}
						}
					}
					.servicecard:hover{
						background-image: url(../../../assets/index/service.png);
						box-shadow: 0px 0px 29px 0px rgba(139, 154, 226, 0.38);
						
					}
					.onlinecard{
						background-image: url(../../../assets/index/online1.png);
						width: 556px;
						height: 184px;
						border-radius: 10px;
						padding: 52px 60px;
						margin-left: 48px;
						background-size: cover;
						.onlinetitle{
							font-size: 24px;
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
							font-weight: bold;
							span{
								margin-left: 7px;
							}
						}
						.onlinetext{
							margin-top: 29px;
							margin-left: 10px;
							font-size: 16px;
							font-family: Medium;
							font-weight: 500;
							color: #777777;
							line-height: 28px;
							width: 376px;
						}
						
						.onlinephone{
							font-size: 24px;
							font-family: SourceHanSansCN-Bold, SourceHanSansCN;
							font-weight: bold;
							margin-top: 26px;
							span{
								margin-left: 7px;
								font-size: 20px;
								font-family: Medium;
								font-weight: 500;
								color: #FFDA0A;
							}
						}
						
						.onlinebutton{
							// position: absolute;
							text-align: right;
							margin-top: 99px;
							.el-button{
								width: 128px;
								height: 55px;
								background: linear-gradient(270deg, #0A89FF 0%, #4371FF 100%);
							}
						}
						
					}
					.onlinecard:hover{
						background-image: url(../../../assets/index/online.png);
						box-shadow: 0px 0px 29px 0px rgba(139, 154, 226, 0.38);
					}
				}
			}
		}
	}

</style>
